global.$ = global.jQuery = require('jquery');
require('parsleyjs');

var LazyLoad = {
  load: function(){
    [].forEach.call(document.querySelectorAll("img[data-src]"), function(img){
      img.setAttribute("src", img.getAttribute("data-src"));
      img.onload = function(){
        img.removeAttribute("data-src");
      };
    });
  }
};



(function(){
  var date = new Date();
	var year = date.getFullYear();
	var copyrightElem = document.getElementById("copyright-year");
	copyrightElem.innerHTML = year;

	$navToggle = $('.nav-toggle span');
	$nav = $('.hidden');
	$navToggle.click(function(){
		$(this).toggleClass('toggled');
		$( $nav ).slideToggle( "fast", function() {
   		// Animation complete.
 		});
	});

  LazyLoad.load();

	var $contactForm = $("#contact-form");

	if($contactForm.length){
		$contactForm.on('submit', function(event){
			event.preventDefault();
		});

		var parsleyForm = $contactForm.parsley({
			trigger: "keyup select focusout"
		})
		.on("form:submit", function(){
	    // // prepare data from form
	  	var postData = $contactForm.serialize();
	    $.ajax({
        url: "https://formspree.io/codonwil@aol.com.com",
	      method: "POST",
	      data : postData,
        dataType: "json"
	    })
	    .done(function(data, status){
				$contactForm.slideUp();
        $(".contact").prepend("<div id='thanks'><h1>Thanks for contacting us! We'll get back to you as soon as possible!</h1></div>");
	    })
	    .fail(function(data, status){
	    });
	  });
	}
})();
